import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'
import { ThemeProvider } from '@leshen/ui'

import boostTheme from '../../../../gatsby-theme-dish/boostTheme'

const BoostTemplate = ({ data }) => {
  return (
    <>
      <ThemeProvider theme={boostTheme}>
        <Layout data={data} />
      </ThemeProvider>
    </>
  )
}

BoostTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default BoostTemplate

export const query = graphql`
  query BoostTemplateQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
